<template>
    <div id="withdraw">
       

      <input v-model="this.returnAddress" placeholder="Recipient's address" />
      <button @click="killWallet(this.returnAddress)">
            Withdraw
      </button>
      <p v-if="withdrawInfo.length === 0">Please paste your own address
      </p>
      <div v-if="withdrawInfo.length > 0" class="withdrawLog">
          {{ withdrawInfo }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'WithdrawC',
    data() {
      return {
        withdrawInfo: [],
        returnAddress: '', 
      }
    },
    methods: {
      async UpdateBalance() {
        await this.$store.dispatch('fetchBalance');
      },
      async killWallet(returnAddress) {
          if(this.$store.state.balance > 0)
          {
          if(this.returnAddress !== '')
          {
            if(!this.$store.state.balance > 0){
              this.$store.commit('afficherNotification','No funds to send');return}
            try {
                const response = await axios.post('https://www.shad0w.space/php/send.php?url=wallet/kill', {
                    wif: this.$store.state.wallet[0],
                    returnA: returnAddress
                });

                const error = response.data.broadcastResponse.error;
                const txid = response.data.broadcastResponse.txid;


                if(error === null)
                {
                  this.$store.commit('afficherNotification',`Success:  ` + txid);
                  if (response.data.wallet) 
                  {
                    this.$store.commit('walletUpdate', [response.data.wallet]);
                  }
                  this.UpdateBalance();
                  this.withdrawInfo = 'Withdrawal Successful:' + txid;
                }else{
                  this.withdrawInfo = 'Withdrawal Error: This wallet is not up to date. Please wait for confirmation and then click the Reset button in the wallet section';
                  
                }
 
                 
            } catch (error) {
                console.error(error);
                this.$store.commit('afficherNotification','Error : ' + error + ' !');
            }
          }else{this.$store.commit('afficherNotification','Recipient\' address required !');}}
           else{this.$store.commit('afficherNotification','Nothing to withdraw.. Balance : ' + this.$store.state.balance);}

      }
    }
  }
  </script>
  
  <style scoped>
  #withdraw {
    margin: 20px auto;
    z-index: 2;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  input {
    width: 90%;  
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
  }
  
  button {
    width: 93%;  
    padding: 10px;
    color: rgb(0, 0, 0);
    background-color: #dfdfdf;
    border: none;
    border-radius: 4px;
    cursor: pointer;  
    transition: background-color 0.2s ease;  
  }
  
  .withdrawLog {
    background-color: #f2f4f8;
    color: #333;
    padding: 10px;
    margin-top: 20px;  
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 90%;  
    font-size: 0.9rem;
    word-wrap: break-word;  
  }
  
  @media (max-width: 768px) {
    #withdraw {
      margin: 10px;
      padding: 15px;
      max-width: 100%;
    }
  }
  </style>
  