<template>
    <div id="mint" class="catFont">

      <div v-if="!this.minting">
            <div class="input-container"> 
              <input v-model="mintData.address" placeholder="Recipient's address" />
              <input v-model="mintData.ticker" placeholder="Ticker" maxlength="4"/>
              <input v-model="mintData.amount" placeholder="Amount" type="number"/>
              <input v-model="repeat" placeholder="Repeat" type="number" min="1" max="10"/>
            </div>

            
            <button @click="mintToken" class=" catFont" v-if="!mintData.ticker">Mint DRC20 {{ repeat }} times</button>
            <button @click="mintToken" class="catFont" v-else>Mint {{ mintData.amount }} {{ mintData.ticker }} {{ repeat }} times</button>
            <p>Please verify this information before minting.</p>
            

            <div>
              <label for="feeSlider" style="display: block; margin-bottom: 3px;text-align: center;">Fee/kb: {{ transactionFee }} DOGE</label>
              <input type="range" id="feeSlider" v-model="transactionFee" min="0.2" max="2" step="0.1" style="width: 100%;" />
              <div style="text-align: center; font-size: 24px;"><span style="font-size: x-small;">{{ feeIndicator }}</span></div>
            </div>
      </div>

      <div v-else>
        <div class="mintLog">
          {{ mintInfo }}
        </div>
        <button @click="resetMintState">Stop</button>
      </div>
    </div>
  </template>
  
<script>
  
  import axios from 'axios';

  export default {
    name: 'MintC',
    data() {
      return {
        mintData: {
              address: '',
              ticker: '',
              amount: '',
              wif: '',
              fee: 0
        }, 
        mintInfo: [], 
        repeat: 1,
        transactionFee: 1,
        minting: false,
        tickerData: {},
      }
    },
    watch: {
      'mintData.ticker': {
        handler(newVal) {
          if(newVal.length === 4) {
            this.fetchTickerData(newVal);
          } else {
            this.tickerData = {}; 
          }
        },
        immediate: true,
      },
      repeat(newVal) {
        if (newVal < 1) {
          this.repeat = 1;
        } else if (newVal > 10) {
          this.repeat = 10;
        }
      }
    },
    computed: {
      feeIndicator() {
        if (this.transactionFee <= 0.30) {
          return '⚡';  
        } else if (this.transactionFee <= 0.60) {
          return '⚡⚡';  
        } else if (this.transactionFee <= 1) {
          return '⚡⚡⚡';  
        } else {
          return '⚡⚡⚡⚡';  
        }
      }
    },
    methods:{
      async fetchTickerData(ticker) {
        const url = `https://www.shad0w.space/php/proxy.php?url=https://api.doggy.market/token/${ticker.toLowerCase()}`;
        try {
          let rep = await axios.get(url);
          this.tickerData = rep.data;  
          if(this.tickerData.lim) {
            this.mintData.amount = this.tickerData.lim;
          }else{this.$store.commit('afficherNotification', "Can't get information, please verify the amount and the remaining supply.");}
          if(this.tickerData.mintedAmt && this.tickerData.max) {
            let notMinted = this.tickerData.max - this.tickerData.mintedAmt;
            this.tickerData.left = notMinted;
            if(this.tickerData.lim > notMinted) {
              this.mintData.amount = notMinted;
            }
            
            let mintedPercentage = (this.tickerData.mintedAmt / this.tickerData.max) * 100;
            this.tickerData.mintedPercent = mintedPercentage.toFixed(2);
            this.$store.commit('afficherNotification', `${this.tickerData.mintedPercent}% Minted | Remaining ${this.tickerData.left} ${this.tickerData.tick}`);

          }
        } catch (error) {
          this.$store.commit('afficherNotification', "This ticker does not exist");
          this.tickerData = {};  
        }
      },
      delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      },
      resetMintState() {
          this.minting = false;
          this.repeat = 1;      
          this.mintData = {         
            address: '',
            ticker: '',
            amount: '',
            wif: ''
          };
      },
      async mintToken() {
            if (this.tickerData.left !== undefined && this.tickerData.left !== null) {
              if (this.tickerData.left === 0) {
                this.$store.commit('afficherNotification', `${this.tickerData.mintedPercent}% Minted | Remaining ${this.tickerData.left} ${this.tickerData.tick}`);
                return;
              }
            }
            if(this.$store.state.balance < 1)
            {
              this.$store.commit('afficherNotification', "Balance Low : need doge");
              return; 
            }
            if(!this.tickerData.tick)
            {
              this.$store.commit('afficherNotification', "This ticker does not exist!");
              return;              
            }
            if(this.tickerData.lim)
            {
              if(this.mintData.amount > this.tickerData.lim)
              {
                this.$store.commit('afficherNotification', `${this.tickerData.lim} ${this.tickerData.tick} max per mint !`);
                return;
              }
            }
            if (!this.mintData.address || !this.mintData.amount || !this.mintData.ticker || !this.repeat || this.repeat <= 0) {
                this.$store.commit('afficherNotification', "Address, Ticker, Amount, and Repeat are required!");
                return;
            }
            if (this.mintData.address === this.$store.state.wallet[0].address) {
                this.$store.commit('afficherNotification', 'This wallet is temporary. You cant send inscriptions to this wallet!');
                return;
            }
            let i = 0;
            this.minting = true;
            while (i < this.repeat) {
                try {
                    this.mintData.wif = this.$store.state.wallet[0];
                    this.mintData.ticker = this.mintData.ticker.toLowerCase();
                    this.mintData.fee = Math.round(this.transactionFee * 1e8);

                    // Vérification du solde
                    //if(this.mint){await this.$store.dispatch('checkBalance')}

                    // Appel à l'API
                    const rep = await axios.post('https://www.shad0w.space/php/send.php?url=doge20/mint', this.mintData);
                    const txs = rep.data.hexTxs;

                    // Envoi et gestion d'erreur
                    for (let tx = 0; tx < txs.length; tx++) {
                        let response = await this.$store.dispatch('sendTx', txs[tx]);

                        if (response.response.error === 'unknow error try again') {
                            this.$store.commit('afficherNotification', 'Your wallet is not up to date, waiting for confirmation and clic reset');
                            this.mintInfo = 'Wallet is not up to date, wait for confirmation and click reset';
                            this.resetMintState();
                            break
                        }

                        while (response.response.error === 'mempool full please wait') {
                            this.$store.commit('afficherNotification', 'Mempool full, retrying in 30secs...');
                            this.mintInfo = `Mint ${i + 1}/${this.repeat} : Mempool full, wait please...`;
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[tx]);
                        }

                        if (response.response.error === null && tx === 1) {
                            this.$store.commit('afficherNotification', response.response.txid);
                            this.mintInfo = `Mint ${i + 1}/${this.repeat}: ${response.response.txid} | Success!`;
                            // Mise à jour du wallet
                            this.$store.commit('walletUpdate', [rep.data.wallet]);
                            this.UpdateBalance();
                            i++; 
                        }
                    }

                    
                } catch (error) {
                    console.log(error);
                    break;
                }
            }

            setTimeout(() => {
              this.$store.commit('afficherNotification', 'Mint Completed');
            }, 2000);
      },
      async UpdateBalance() {
        await this.$store.dispatch('fetchBalance');
      },
    }
  }
</script>



<style scoped>
.mintLog {
    background-color: #f2f4f8;
    color: #333;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    box-sizing: border-box;  
    width: 100%;  
}

  #mint {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 2;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .input-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    background-color: #dfdfdf;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
  }

  button:hover {
    background-color: #0056b3;
  }

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  #feeSlider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  #feeSlider:hover {
    opacity: 1;
  }

  #feeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('../assets/doge.svg') no-repeat center center;  
  background-size: cover;  
  cursor: pointer;
}

#feeSlider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('../assets/doge.svg') no-repeat center center;  
  background-size: cover;  
  cursor: pointer;
}

@media (max-width: 768px) {
    .mintLog, .input-container, #mint {
        padding: 5px;  
        margin: 0 auto;
        max-width: 100%;  
        word-break: break-word;
    }
}
</style>
