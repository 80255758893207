/* eslint-disable */

import { createStore } from 'vuex';
import axios from 'axios';

// Créez le store Vuex
const store = createStore({
  state() {
    return {
      wallet: [],
      balance: '0',
      notification: {
        message: '',
        visible: false
      },
      savedImageForMinting: null,
    };
  },
  mutations: {
    saveImageForMinting(state, imageData) {
      state.savedImageForMinting = imageData;
    },
    clearSavedImageForMinting(state) {
      state.savedImageForMinting = null;
    },
    walletUpdate(state, update) {
        state.wallet = update;
    },
    updateBalance(state, newBalance) {
        state.balance = newBalance;
    },
    afficherNotification(state, message) {
        state.notification.message = message;
        state.notification.visible = true;
        setTimeout(() => {
          state.notification.visible = false;
        }, 5000);
    },

  },
  actions: {
    async sendTx({ commit }, tx) { 
        const endpoint = 'https://www.shad0w.space/php/send.php?url=send/tx';
        try {
          const response = await axios.post(endpoint, { tx });
          return response.data;
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
    },
    async newWallet({ commit }) {
        try {
          const response = await fetch('https://www.shad0w.space/php/send.php?url=wallet/new/');
          const data = await response.json();
          let wallet = [];
          wallet.push({
            privateKey: data.privkey,
            address: data.address,
            utxos: []
          });
          commit('walletUpdate', wallet);
        } catch (error) {
          console.error(error);
        }
    },
    async login({ commit, state, dispatch }) {
        try {
          const response = await axios.post('https://www.shad0w.space/php/send.php?url=login', { 
            wif: state.wallet[0].privateKey 
          });
          commit('walletUpdate', [response.data.response]);
          dispatch('fetchBalance');
          commit('afficherNotification', 'Update Balance...');
        }catch (error) {
            console.log(error.message); 
        }
    },
    async checkWallet({ commit, state, dispatch }, wif) {
      try {
        const response = await axios.post('https://www.shad0w.space/php/send.php?url=login', { 
          wif: wif,
           
        });
        return response.data.response;
      } catch (error) {
          console.log(error.message); 
      }
    },
    async fetchBalance({ commit, state}) {
        try {
  
          let balance = state.wallet[0]?.utxos.reduce((acc, utxo) => acc + utxo.satoshis, 0) / 100000000;
          balance = parseFloat(balance.toFixed(2));
          commit('updateBalance', balance);
  
        } catch (error) {
          console.error('Error : ', error);
        }
    },
    async newWalletX({ commit }) {
        try {
          const response = await fetch('https://www.shad0w.space/php/send.php?url=wallet/new/');
          const data = await response.json();
          let wallet = [];
          wallet.push({
            privateKey: data.privkey,
            address: data.address,
            utxos: []
          });
          return wallet;
        } catch (error) {
          console.error(error);
        }
    },
  },
  getters: {
    
  }
});

export default store;
