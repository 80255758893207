<template>
    <div id="container">
      <div id="colorPalette">
        <button class="colorButton" @click="selectColor('#000000')" style="background-color: #000000;"></button>
        <button class="colorButton" @click="selectColor('#FF0000')" style="background-color: #FF0000;"></button>
        <button class="colorButton" @click="selectColor('#00FF00')" style="background-color: #00FF00;"></button>
        <button class="colorButton" @click="selectColor('#0000FF')" style="background-color: #0000FF;"></button>
        <button class="colorButton" @click="selectColor('#FFFFFF')" style="background-color: #FFFFFF; border: 1px solid black;"></button>
      </div>
      <canvas ref="canvas" 
              width="256" height="256" 
              @mousedown="handleMouseDown" 
              @mousemove="handleMouseMove" 
              @mouseup="handleMouseUp"
              @mouseleave="handleMouseLeave"></canvas>
            <div id="actionButtons">
                <button @click="downloadImage" class="actionButton">Download</button>
                <button @click="clearCanvas" class="actionButton clearButton">Clear</button>
                <button @click="saveToMint" class="actionButton">Mint !</button>

            </div>

    </div>
  </template>
  
<script>
export default {
  name: 'DrawingC',
  data() {
    return {
      canvas: null,
      ctx: null,
      scale: 4,
      selectedColor: '#000000',
      isDrawing: false,
    };
  },
  mounted() {
    this.initializeCanvas();
    this.canvas.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    this.canvas.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    this.canvas.addEventListener('touchend', this.handleTouchEnd, { passive: false });
  },
  methods: {
    saveToMint() {
      const dataURL = this.canvas.toDataURL('image/png');
      this.$store.commit('saveImageForMinting', dataURL);
      this.$router.push('/nft');
    },
    initializeCanvas() {
      this.canvas = this.$refs.canvas;
      this.ctx = this.canvas.getContext('2d');
      this.ctx.fillStyle = '#FFFFFF';
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    handleMouseDown() {
      this.isDrawing = true;
    },
    handleMouseMove(event) {
      if (this.isDrawing) {
        this.draw(event);
      }
    },
    handleMouseUp() {
      this.isDrawing = false;
    },
    handleMouseLeave() {
      this.isDrawing = false;
    },
    handleTouchStart(event) {
      event.preventDefault();
      this.isDrawing = true;
      this.draw(event.touches[0]);
    },
    handleTouchMove(event) {
      event.preventDefault();
      if (this.isDrawing) {
        this.draw(event.touches[0]);
      }
    },
    handleTouchEnd() {
      this.isDrawing = false;
    },
    draw(eventOrTouch) {
      const rect = this.canvas.getBoundingClientRect();
      const x = (eventOrTouch.clientX || eventOrTouch.pageX) - rect.left;
      const y = (eventOrTouch.clientY || eventOrTouch.pageY) - rect.top;
      const col = Math.floor(x / this.scale);
      const row = Math.floor(y / this.scale);

      this.ctx.fillStyle = this.selectedColor;
      this.ctx.fillRect(col * this.scale, row * this.scale, this.scale, this.scale);
    },
    downloadImage() {
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      const targetWidth = this.canvas.width / 2;
      const targetHeight = this.canvas.height / 2;
      tempCanvas.width = targetWidth;
      tempCanvas.height = targetHeight;
      tempCtx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height, 0, 0, targetWidth, targetHeight);

      const link = document.createElement('a');
      link.download = 'drawing.png';
      link.href = tempCanvas.toDataURL('image/png', 0.4);
      link.click();
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fillStyle = '#FFFFFF';
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    selectColor(color) {
      this.selectedColor = color;
    }
  }
}
</script>

  <style scoped>
  canvas{
    border: 1px solid black;
  }
  #container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    z-index: 2;
  }
  
  #colorPalette {
    display: flex;
    margin-bottom: 20px;
  }
  
  .colorButton {
    width: 30px; /* Taille réduite */
    height: 30px; /* Taille réduite */
    margin-right: 5px;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .colorButton:last-child {
    margin-right: 0;
  }
  
  #actionButtons {
    display: flex; 
    gap: 10px; 
    width: 100%; 
    max-width: 300px; 
    margin-top: 10px;
  }
  
  .actionButton {
    flex: 1; 
    padding: 5px 15px; 
    font-size: 14px; 
    color: rgb(0, 0, 0);
    background-color: #dfdfdf;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  </style>
  