<template>
    <div id="container">
        <!-- Boutons non cliquables -->
        <button disabled>Coin Flip (Soon)</button>
        <button disabled>Gatcha (Soon)</button>
    </div>
</template>
<script>
export default {
      name: 'GamesC',
}
</script>
<style scoped>
  #container {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 2;
  }

  /* Style pour les boutons désactivés */
  #container button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    background-color: #dfdfdf;
    font-size: 16px;
    width: 300px;
  }
</style>
