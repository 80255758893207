<template>
    <div id="wallet" class="catFont">
        

      <!--CREATE WALLET-->
      <div v-if="!$store.state.wallet.length > 0">
        <button @click="newWallet">Create New Wallet</button>
        <br>
        <div class="input-button-container">
          <input type="text" v-model="wif" placeholder="Private Key (WIF)">
          <button @click="addCustomWallet()">Import</button>
        </div>

        <p style="text-align: center;">Do <span style="color: darkred;">NOT</span> import your personal wallet here, only wallets created from this application.</p>
      </div>

      
      <div v-if="$store.state.wallet.length > 0" class="infoContent">
        <small style="cursor: pointer;" @click="copy($store.state.wallet[0].privateKey)">Click to copy</small>
        <p @click="copy($store.state.wallet[0].privateKey)" class="info">
          {{ $store.state.wallet[0].privateKey }}
        </p>
        
        <br>
        <p style="font-weight: bold;">This is your private key. Please save it securely to ensure you do not lose access to your wallet. We are not responsible for any consequences arising from your actions.</p>
        <br>
        <p>This wallet does not allow the storage of inscriptions. Please send your inscriptions to your own wallet.</p>
        <button @click="login" class="catFont">Deposit / Reset</button>
      </div>


    </div>
  </template>
  
  <script>
  export default {
    name: 'WalletC',
    data() {
      return {
        wif: '',  
      }
    },
    methods: {
        async login(){
            await this.$store.dispatch('login');
        },
        async newWallet() {
          await this.$store.dispatch('newWallet');
        },
        async addCustomWallet() {
          if(this.wif !== ''){
            const newWallet = {
            privateKey: this.wif,
            address: "-Loading-",
            utxos: []
          };
          let wallet = [];
          wallet.push(newWallet);
          await this.$store.commit('walletUpdate', wallet);
          await this.$store.dispatch('login');
          }else{this.$store.commit('afficherNotification', 'Need a private key (wif)');}
        },
        copy(copy) {
        navigator.clipboard.writeText(copy).then(() => {
          this.$store.commit('afficherNotification', 'Copy to clipboard !');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      },
    }
  }
  </script>
<style scoped>
  .infoContent{
    max-width: 90%;  
    box-sizing: border-box;
  }
  .info{
    background-color: #f2f4f8;
    color: #333;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    cursor:grabbing;
    overflow-x: scroll;
  }

#wallet  {
  margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 2;
}
h3 {
  color: #333;
  margin-bottom: 20px;
}

p {
  text-align: center;
  margin: 10px 0;
}

input[type="text"], button {
  padding: 10px 20px;
  margin: 3px 0;
  width: 100%;
  box-sizing: border-box;
}

input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  font-size: 1rem;
  
  color: rgb(0, 0, 0);
  background-color: #dfdfdf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  box-sizing: border-box;
}



span {
  font-weight: bold;
}

@media (max-width: 768px) {
  #wallet {
    margin: 10px;
    padding: 15px;
    max-width: 90%;
  }
  
}
</style>

  