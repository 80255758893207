<template>
    <div id="deploy" class="catFont">
        <div id="container">
            <input id="address" v-model="deployData.address" placeholder="Address" class="input-field"/>
            <input id="ticker" v-model="deployData.ticker" maxlength="4" placeholder="Ticker" class="input-field"/>
            <input id="max" v-model="deployData.max" placeholder="Max" class="input-field"/>
            <input id="limit" v-model="deployData.limit" placeholder="Limit" class="input-field"/>
            
            <button @click="deployToken" class="button-secondary" style="margin-bottom: 15px;">Deploy</button>
             
            <div>
              <label for="feeSlider" style="display: block; margin-bottom: 3px;text-align: center;">Fee/kb: {{ transactionFee }} DOGE</label>
              <input type="range" id="feeSlider" v-model="transactionFee" min="0.2" max="2" step="0.1" style="width: 100%;" />
              <div style="text-align: center; font-size: 24px;"><span style="font-size: x-small;">{{ feeIndicator }}</span></div>
            </div>
        </div>
    </div>
</template>
  
<script>
  import axios from 'axios';
  export default {
    name: 'DeployC',
    data() {
      return {
        deployData: 
        {
                address: '',
                ticker: '',
                max: '',
                limit: '',
                wif: ''
        },
        transactionFee: 1,
      }
    },
    computed: {
      feeIndicator() {
        if (this.transactionFee <= 0.30) {
          return '⚡'; // Premier quart
        } else if (this.transactionFee <= 0.60) {
          return '⚡⚡'; // Deuxième quart
        } else if (this.transactionFee <= 1) {
          return '⚡⚡⚡'; // Troisième quart
        } else {
          return '⚡⚡⚡⚡'; // Dernier quart
        }
      }
    },
    methods: {
      async checkingTicker(ticker){
          const url = `https://www.shad0w.space/php/proxy.php?url=https://api.doggy.market/token/${ticker.toLowerCase()}`;
          try {
            await axios.get(url);
            return true;
          } catch (error) {
            this.$store.commit('afficherNotification', "Ticker doesn't exist");
            return false;
          }
      },
      async deployToken() {
            if(this.deployData.address && this.deployData.limit && this.deployData.ticker && this.deployData.max)
            {
              if(!await this.checkingTicker(this.deployData.ticker.toLowerCase())){
                try {
                      this.deployData.wif = this.$store.state.wallet[0];
                      this.deployData.ticker = this.deployData.ticker.toLowerCase();
                      this.deployData.fee = Math.round(this.transactionFee * 1e8);

                      const rep = await axios.post('https://www.shad0w.space/php/send.php?url=doge20/deploy', this.deployData);
                      const txs = rep.data.hexTxs;



                      
                    // Envoi et gestion d'erreur
                    for (let tx = 0; tx < txs.length; tx++) {
                        let response = await this.$store.dispatch('sendTx', txs[tx]);

                        if (response.response.error === 'unknow error try again') {
                          this.$store.commit('afficherNotification', "Your wallet is not up to date, waiting for confirmation and clic reset");
                            break;
                        }

                        while (response.response.error === 'mempool full please wait') {
                          this.$store.commit('afficherNotification', "Mempool full, retrying in 30secs...");
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[tx]);
                        }

                        if (response.response.error === null && tx === 1) {
                          // Mise à jour du wallet
                          this.$store.commit('walletUpdate', [rep.data.wallet]);
                          this.$store.commit('afficherNotification', "Success : " + response.response.txid);
                        }
                    }
                      
                    } catch (error) {
                      console.error(error.response ? error.response.data : error.message);
                      this.$store.commit('afficherNotification', "Deploy error:" + error.response ? error.response.data : error.message);
                    }
              }else{this.$store.commit('afficherNotification', "Ticker Not Avalaible!")}

            }else{this.$store.commit('afficherNotification',"Deploy : address, ticker, max and limit are required")}
      },
    }
  }
</script>
<style scoped>
 
#deploy {
  margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 2;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;  
  margin-bottom: 10px;  
}

.button-secondary {
  padding: 10px 20px;
  font-size: 1rem;
  color: rgb(0, 0, 0);
  background-color: #dfdfdf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;  
  margin-top: 10px; 
}

.button-secondary:hover {
  background-color: #0056b3;
}

label {
  font-size: 1rem;
  margin-bottom: 5px;  
  display: block;  
  text-align: center;  
}

#feeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

#feeSlider:hover {
  opacity: 1;
}

#feeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('../assets/doge.svg') no-repeat center center;  
  background-size: cover;  
  cursor: pointer;
}

#feeSlider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('../assets/doge.svg') no-repeat center center;  
  background-size: cover;  
  cursor: pointer;
}

@media (max-width: 768px) {
  #deploy {
    margin: 10px;
    padding: 15px;
    max-width: 100%;
  }

  .input-field, .button-secondary {
    width: 100%;  
  }
}
</style>
