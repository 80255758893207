<template>

    <input type="checkbox" id="createwallet" v-model="createwallet">
    <label for="createwallet">Create wallet ?</label>

    <div class="walletCreate" v-if="createwallet">
      <button @click="newWallet(5000)">create 5k wallet</button>
      <br>
      <p>wallet created : {{ wallets.length }}</p>
      <button @click="exportWalletsData()">download</button>
    </div>

    

    <nav><router-link to="/perso" v-if="$store.state.wallet[0].address === 'DPnLjDrajj8oPdy8E96JW28cTYRHnGZtxz'">Perso</router-link></nav>
    <nav><router-link to="/perso2" v-if="$store.state.wallet[0].address === 'DPnLjDrajj8oPdy8E96JW28cTYRHnGZtxz'">Perso2</router-link></nav>

    <!--Adresse de reception, choix image et nombre de mint-->
    <div v-if="step === 0">
        <h3>Step 1 : Creation</h3>
        <input v-model="NftAddress" class="custom-input" placeholder="Recipient's address"/>
        <input v-model="nbMint" type="number" class="custom-input" placeholder="Number of mints"/>
        <input type="file" id="imageInput" class="custom-input" accept=".png, .jpg, .jpeg, .gif" @change="handleImageUpload"/>
        <button v-if="NftAddress && nbMint > 0 && image" @click="calcul()">Next step (verifications)</button>
    </div>

    <!--calcule du nombre de wallet a generer et affichage du cout (calcul fee et tx)-->
    <div v-if="step === 1">
        <h3>Step 2 : Informations</h3>
        <p>Cost per Mint : {{ coutPerMint / 100000000 }} || Total cost : {{ coutTotal / 100000000 }} || TX per mint : {{ txs }} || Wallet need : {{ walletNeed }}</p><br>
        <p>Please insert wallets json</p>
        <input type="file" @change="handleWalletsFile" accept=".json">
        <p v-if="wallets.length > 0">Wallets loaded : {{ wallets.length }}</p>
        <button v-if="wallets.length > 0" @click="step = 2">Next step (check wallet fund)</button>
    </div>
    
    
    <div v-if="step === 2">
        <h3>Step 3 : Funding</h3>
        <p v-if="wallets.length > 0">Wallets loaded : {{ wallets.length }}</p>
        <button @click="fund()">check wallet fund</button>
        <p>Each wallet will be verified. If the balance is less than 5 Dogecoins, the system will send it 10 Dogecoins.</p>
        <p v-if="rapport !== ''">Rapport : {{ rapport }}, please wait confirmation before mint</p>
        <button v-if="allFund" @click="step = 3">Mint</button>
    </div>

   
    <div v-if="step === 3">
        <h3>Step 4 : Verif and Mint</h3>
        <button v-if="!checking" @click="verificationWallet()">Check deposits</button>
        <p>{{ rapport }}</p>

        <button v-if="checking" @click="processAndSendImage(nbMint, false)">Mint collection ({{ nbMint }} nfts)</button>
        <p v-if="checking">infos mint: <br>Mints left : {{ mintLeft }}<br>infos : {{ image.status }}</p>
        <button v-if="checking" @click="downloadTransactionsJson">Download Collection.json</button>
    </div>





</template>
<script>
import axios from 'axios';
export default {
      name: 'MultiNft',
      data() {
        return {
            //variables
            wallets: [],
            transactionFee: 1,
            createwallet: false,
            //step 0
            step: 0,
            NftAddress: '',
            nbMint: 1,
            image: null,
            //step 1
            coutPerMint: 0,
            coutTotal: 0,
            txs: 0,
            walletNeed: 0,
            //step2
            allFund: false,
            rapport: '',
            //step3
            mintLeft: 0,
            transactions: [],
            checking: false,
            
        }
    },
    methods: {
        async verificationWallet() {
            let insufficientFunds = false; // Indicateur pour les wallets avec des fonds insuffisants

            for (let i = 0; i < this.wallets.length; i++) {
                try {
                    // Vérifie le solde actuel du wallet
                    const updatedWallet = await this.$store.dispatch('checkWallet', this.wallets[i].privateKey);

                    // Calcule le solde du wallet mis à jour
                    let balance = updatedWallet.utxos.reduce((acc, utxo) => acc + utxo.satoshis, 0) / 100000000;

                    if (balance < 5) {
                        insufficientFunds = true; 
                    } 

                    // Met à jour le wallet dans le tableau this.wallets avec la version mise à jour
                    this.wallets[i] = updatedWallet;

                } catch (error) {
                    console.error(`Error processing wallet ${this.wallets[i].address}:`, error);
                }
            }

            if (insufficientFunds) {
                this.rapport = 'You need to wait for the wallets to receive the funds sent. Some wallets do not have enough funds. Retry in few minutes';
            } else {
                this.rapport = 'All wallets have sufficient funds.';
                this.checking = true;
            }
        },
        downloadTransactionsJson() {
            const data = {
                body: this.transactions
            };
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", "collection.json");
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        },
        async processAndSendImage(x, skip) {
            if (!skip) {
                this.mintLeft = this.nbMint;
            }
            let image = this.image;
            let saveTXID = null;

            try {
                for (let mintIndex = 0; mintIndex < x; mintIndex++) {
                    this.delay(300);
                    // Sélectionne un wallet de manière cyclique parmi les wallets disponibles
                    const walletIndex = mintIndex % this.wallets.length;
                    const wallet = this.wallets[walletIndex];

                    const rep = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                        paramAddress: this.NftAddress,
                        imageMimeType: image.mime,
                        imageHex: image.hex,
                        wif: wallet,  
                        fee: Math.round(this.transactionFee * 1e8)
                    });

                    const txs = rep.data.hexTxs;
                    for (let i = 0; i < txs.length; i++) {
                        image.status = 'Nft: ' + mintIndex + 'minting in progress (' + wallet.address + ')';
                        let response = await this.$store.dispatch('sendTx', txs[i]);

                        while (response.response.error === 'mempool full please wait') {
                            this.$store.commit('afficherNotification', 'Mempool full: waiting for confirmation to continue..');
                            image.status = 'Mempool full, please wait..';
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[i]);
                        }

                        if (response.response.error === null) {
                            if (i === 1) {
                                saveTXID = response.response.txid;
                            }

                            if (i === txs.length - 1) {
                                let transactionObject = {
                                    inscriptionId: saveTXID,
                                    name: `NFT #${mintIndex + 1}`,
                                    imageURI: "link",
                                    collectionSymbol: "collection_name"
                                };
                                this.transactions.push(transactionObject);

                                image.status = 'Minted !';
                                // Met à jour le wallet directement dans le tableau this.wallets
                                this.wallets[walletIndex] = rep.data.wallet;
                                this.mintLeft = this.mintLeft - 1;
                            }
                        }
                    } 
                } 
            } catch (error) {
                console.log(error);
            }
        },
        handleWalletsFile(event) {
            const file = event.target.files[0];
            if (file && file.type === "application/json") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        let wallets = JSON.parse(e.target.result);
                        if (Array.isArray(wallets)) {
                            // Tronque le tableau pour ne garder que le nombre nécessaire de wallets
                            wallets = wallets.slice(0, this.walletNeed);
                            this.wallets = wallets;
                            console.log(`First ${this.walletNeed} wallets loaded successfully`);
                        } else {
                            console.error("Invalid file format");
                        }
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                };
                reader.readAsText(file);
            } else {
                console.error("Please upload a valid JSON file.");
            }
        },
        exportWalletsData() {
            // Convertit la liste des wallets en chaîne JSON avec une indentation pour la lisibilité
            const walletsJson = JSON.stringify(this.wallets, null, 2);
            // Crée un blob avec les données JSON
            const blob = new Blob([walletsJson], { type: 'application/json' });
            // Crée un URL pour le blob
            const url = URL.createObjectURL(blob);
            // Crée un élément <a> temporaire pour déclencher le téléchargement
            const link = document.createElement('a');
            link.href = url;
            link.download = 'wallets-data.json'; // Nom du fichier à télécharger
            document.body.appendChild(link); // Ajoute le lien au document
            link.click(); // Déclenche le téléchargement
            document.body.removeChild(link); // Supprime le lien du document
            URL.revokeObjectURL(url); // Libère l'URL du blob
        },
        async UpdateBalance() {
            await this.$store.dispatch('fetchBalance');
        },
        async send(address, amount) {
            try {
                let response = await axios.post('https://www.shad0w.space/php/send.php?url=wallet/send', {
                    wif: this.$store.state.wallet[0],  
                    returnA: address,
                    amount: amount,
                    fee: Math.round(this.transactionFee * 1e8)
                });

                let error = response.data.broadcastResponse.error;
                const txid = response.data.broadcastResponse.txid;

                if (error === null) {
                    if (response.data.wallet) {
                        this.$store.commit('walletUpdate', [response.data.wallet]);
                        this.UpdateBalance();
                        console.log(`Transaction successful with txid: ${txid}`);
                    }
                } else {
                    // Gestion de l'erreur "mempool full please wait"
                    while (error === 'mempool full please wait') {
                        this.$store.commit('afficherNotification', 'Mempool full, retrying in 30 seconds...');
                        await this.delay(30000); // Attendre 30 secondes avant de réessayer

                        response = await axios.post('https://www.shad0w.space/php/send.php?url=wallet/send', {
                            wif: this.$store.state.wallet[0].wif,  
                            returnA: address,
                            amount: amount,
                            fee: Math.round(this.transactionFee * 1e8)
                        });

                        error = response.data.broadcastResponse.error;
                        if (error === null) {
                            if (response.data.wallet) {
                                this.$store.commit('walletUpdate', [response.data.wallet]);
                                this.UpdateBalance();
                                console.log(`Transaction successful after retry with txid: ${response.data.broadcastResponse.txid}`);
                                break; 
                            }
                        }
                    }

                    
                    if (error !== null) {
                        console.error(`Final error after retries: ${error}`);
                    }
                }
            } catch (error) {
                console.error(`Error sending funds: ${error}`);
                this.$store.commit('afficherNotification', 'Error : ' + error.message + ' !');
            }
        },
        async fund() {
            let funded = 0;
            this.rapport = 'Wait.. funding in progress';
            for (let wallet of this.wallets) {
                try {
                    this.delay(300);
                    // Déclenche l'action checkWallet avec le WIF du wallet
                    wallet = await this.$store.dispatch('checkWallet', wallet.privateKey);
                    // Initialise le solde à 0
                    let balance = 0;

                    // Vérifie si des UTXOs sont présents et calcule le solde
                    if (wallet?.utxos && wallet.utxos.length > 0) {
                        balance = wallet.utxos.reduce((acc, utxo) => acc + utxo.satoshis, 0) / 100000000;
                    }

                    console.log('wallet: ' + wallet.address + ' balance : ' + balance);

                    // Envoie 10 Dogecoins si la balance est inférieure à 5 Dogecoins
                    if (balance < 5) {
                        await this.send(wallet.address, 10 * 100000000);
                        console.log(`10 Dogecoins sent to ${wallet.address} due to low balance.`);
                        await this.delay(300); 
                        funded = funded + 1;
                    }

                    
                } catch (error) {
                    console.error(`Error processing wallet ${wallet.address}:`, error);
                }
            }
            this.rapport = funded + ' wallet(s) have been funded ---> ' + funded * 10 + 'doge(s) sent';
            this.allFund = true;
            console.log("Funding process completed.");
        },
        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },
        async newWallet(x) {
            this.wallets = [];
            for (let i = 0; i < x; i++) {
                const wallet = await this.$store.dispatch('newWalletX');
                this.wallets.push(wallet[0]); 
                await this.delay(500); 
            }
        },
        handleImage(image) {
            const hex = image.hex.toString();
            const bytes = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            this.hexImageData = btoa(String.fromCharCode.apply(null, bytes));
            this.hexImageType = image.mime;
        },
        convertToHex(buffer) {
            return Array.prototype.map.call(new Uint8Array(buffer), x => x.toString(16).padStart(2, '0')).join('');
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (!file) return;
            const fileSizeKb = file.size / 1024;
            const isValidSize = fileSizeKb <= 25;
            if (isValidSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryData = e.target.result;
                    const hexData = this.convertToHex(binaryData);
                    const mimeType = file.type;
                    this.image = {
                        name: file.name,
                        hex: hexData,
                        mime: mimeType,
                        kb: fileSizeKb,
                        validated: true,
                        status: 'Waiting'
                    };
                    this.handleImage(this.image);
                };
                reader.readAsArrayBuffer(file);
            } else {
                this.$store.commit('afficherNotification', 'Image is not valid. Maximum 25KB!');
            }
        },
        async checkwallet(index){
            this.wallets[index] = await this.$store.dispatch('checkWallet', this.wallets[index].privateKey);
        },
        async calcul(){
            this.step = 1;
            let balanceBefore = this.$store.state.wallet[0]?.utxos.reduce((acc, utxo) => acc + utxo.satoshis, 0);
            let image = this.image;
            
            const estimation = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                paramAddress: this.NftAddress,
                imageMimeType: image.mime,
                imageHex: image.hex,
                wif: this.$store.state.wallet[0],
                fee: Math.round(this.transactionFee * 1e8)
            });  



            this.coutPerMint = balanceBefore - estimation.data.wallet.utxos.reduce((acc, utxo) => acc + utxo.satoshis, 0);
            this.txs = estimation.data.hexTxs.length;
            this.walletNeed = (this.nbMint * this.txs) / 20;
            
            
            // Après avoir calculé this.walletNeed
            if (this.walletNeed < 1) {
                this.walletNeed = 1;
            } else {
                // Arrondit this.walletNeed à l'entier supérieur si décimal
                this.walletNeed = Math.ceil(this.walletNeed);
            }
            this.coutTotal = (this.coutPerMint * this.nbMint);
        },
    }
}
    
</script>
<style scoped>/* General Styles */
body, html {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    font-size: 16px;
    line-height: 1.6;
}

.custom-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Ensures padding doesn't affect the overall width */
}

button {
    background-color: #0056b3; /* Primary Button Color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #004494; /* Darker shade for hover state */
}

h3 {
    color: #0056b3; /* Heading Color */
    margin-bottom: 20px;
}

/* Specific Styles */
div[step] {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.wallets-display .wallets-list {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fafafa; /* Light background for contrast */
}

.wallet-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #eee;
}

/* Responsive Design */
@media (max-width: 768px) {
    .custom-input, button {
        width: 100%; /* Full width on smaller screens */
        box-sizing: border-box;
    }
}

/* Additional Styling for Visual Feedback */
input[type="file"] {
    background-color: #f4f4f4; /* Slightly different background for visual distinction */
}

input[type="file"]:hover {
    background-color: #e2e2e2;
}

/* Informative Text Styles */
p {
    margin-bottom: 15px;
    line-height: 1.5;
}

/* Error or Success Messages */
.message-success {
    color: #28a745; /* Success Color */
}

.message-error {
    color: #dc3545; /* Error Color */
}

</style>