<template>
    <div id="mint" class="catFont">

      <div>
            <div class="input-container"> 
              <input v-model="mintData.address" placeholder="Recipient's address" />
              <input v-model="mintData.code" placeholder="code here..."/>
            </div>

            <button @click="check">hex</button>
            <button @click="mint" class="catFont">Mint</button>
            
            <p>{{ mintData.code }}</p>

            <div>
              <label for="feeSlider" style="display: block; margin-bottom: 3px;text-align: center;">Fee/kb: {{ transactionFee }} DOGE</label>
              <input type="range" id="feeSlider" v-model="transactionFee" min="0.2" max="3" step="0.1" style="width: 100%;" />
              <div style="text-align: center; font-size: 24px;"><span style="font-size: x-small;">{{ feeIndicator }}</span></div>
            </div>
      </div>


      
    </div>
  
  </template>
  
<script>
  
  import axios from 'axios';

  export default {
    name: 'PersoC',
    data() {
      return {
        repeat: 0,
        mintData: {
          address: 'DESukXs8riTxDgx5bbCrWkpsHBZxfhAEqq',
          mime: 'text/plain;charset=utf-8',
          code: '{"p":"tap","op":"token-mint","tick":"tap","amt":"1000"}',
          wif: {
            privateKey: "QPLLk9M2wv8uwy6dinP4e25z1Y4T7Qb6mYkhNwaXC3XTfWxFG1sd",
            address: "DPnLjDrajj8oPdy8E96JW28cTYRHnGZtxz",
            utxos: [{
              txid: "bc6c23a2799df3b33555958ecb37afa9c489be1c5691fa4d1e3b671436445750",
              vout: 1,
              script: "76a914cc7d7fc545bcc6339c4e33e1112502acf0c309bf88ac",
              satoshis: 9739650000
            }]
          },
          fee: 0
        },
        transactionFee: 1,
      }
    },
    computed: {
      feeIndicator() {
        if (this.transactionFee <= 0.30) {
          return '⚡';  
        } else if (this.transactionFee <= 0.60) {
          return '⚡⚡';  
        } else if (this.transactionFee <= 1) {
          return '⚡⚡⚡';  
        } else {
          return '⚡⚡⚡⚡';  
        }
      }
    },
    methods:{
    convertToHex(buffer) {
            return Array.prototype.map.call(new Uint8Array(buffer), x => x.toString(16).padStart(2, '0')).join('');
      },
    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      },
      check() {
    // Assurez-vous que la chaîne est correctement convertie en ArrayBuffer ou Uint8Array
    const encoder = new TextEncoder();
    const encoded = encoder.encode(this.mintData.code);
    this.mintData.code = this.convertToHex(encoded);
    },
    async mint() {

         
                try {
                    
                    if(this.repeat > 0)
                    {
                      this.mintData.wif = this.$store.state.wallet[0];
                    }
                    
                    this.mintData.fee = Math.round(this.transactionFee * 1e8);

                    // Appel à l'API
                    const rep = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                    paramAddress: this.mintData.address,
                    imageMimeType: this.mintData.mime,
                    imageHex: this.mintData.code,
                    wif: this.mintData.wif,
                    fee: this.mintData.fee
                    });
                    const txs = rep.data.hexTxs;

                    // Envoi et gestion d'erreur
                    for (let tx = 0; tx < txs.length; tx++) {
                        let response = await this.$store.dispatch('sendTx', txs[tx]);

                        if (response.response.error === 'unknow error try again') {
                            this.$store.commit('afficherNotification', 'Your wallet is not up to date, waiting for confirmation and clic reset');
                           
                            this.resetMintState();
                            break
                        }

                        while (response.response.error === 'mempool full please wait') {
                            this.$store.commit('afficherNotification', 'Mempool full, retrying in 30secs...');
                            
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[tx]);
                        }

                        if (response.response.error === null && tx === 1) {
                            this.$store.commit('afficherNotification', response.response.txid);
                            this.repeat = 1;
                            // Mise à jour du wallet
                            this.$store.commit('walletUpdate', [rep.data.wallet]);
                            this.UpdateBalance();
                        
                        }
                    }

                    
                } catch (error) {
                    console.log(error);
                }
            

            setTimeout(() => {
              this.$store.commit('afficherNotification', 'Mint Completed');
            }, 2000);
      },
    async UpdateBalance() {
        await this.$store.dispatch('fetchBalance');
      },
    }
  }
</script>