<template>
    <footer class="footer">
      <div class="footer-content">
        <p>&copy; 2024 LumX</p>
        <div class="footer-links">
          <a href="https://t.me/LumXOfficialPortal" target="_blank">Telegram</a>
          <a href="https://twitter.com/Lumxdrc20" target="_blank">Twitter</a>
        </div>

      </div>
    </footer>
</template>
<script>
export default {
      name: 'FooterC',
}
    
</script>
<style scoped>
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ccc;
}

@media (min-width: 768px) {
  .footer{
    margin-top: 25px ;
  }
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-links {
    gap: 30px;
  }
}
</style>
