<template>
    <div id="Nft" class="catFont">
        <div v-if="image === null" class="box">
            <p class="catFont">Mint your NFT</p>
            <input v-model="NftAddress" placeholder="Recipient's address"  style="width: 100%;"/>
            <p v-if="NftAddress && !isSavedImageAvailable" style="text-align: center;">25kb max - jpg - png</p>
            <input v-if="NftAddress && !isSavedImageAvailable" type="file" id="imageInput" accept=".png, .jpg, .jpeg" @change="handleImageUpload"/>
        </div>

        <div v-if="hexImageData" class="nft-image-container">
                <img :src="'data:' + hexImageType + ';base64,' + hexImageData" alt="Loaded Image" />
        </div>
        <p v-if="this.image !== null" class="log">{{ image.status }}</p>

        <button v-if="isSavedImageAvailable && NftAddress" @click="mintSavedImage" class="actionButton">Mint your creation</button>
        <button v-if="isSavedImageAvailable && NftAddress" @click="$store.commit('clearSavedImageForMinting');" class="actionButton">Upload an image</button>

        <div v-if="image" class="buttons-container">
            <button @click="processAndSendImage()">Mint NFT</button>
            <button @click="image = null;hexImageData = '';hexImageType = '';">Delete Image</button>
        </div>

        <div v-if="image !== null">
            <label for="feeSlider" style="display: block; margin-bottom: 3px;text-align: center;">Fee/kb: {{ transactionFee }} DOGE</label>
              <input type="range" id="feeSlider" v-model="transactionFee" min="0.2" max="2" step="0.1" style="width: 100%;" />
              <div style="text-align: center; font-size: 24px;"><span style="font-size: x-small;">{{ feeIndicator }}</span></div>
        </div>
    </div>
  </template>
    
    <script>
    import axios from 'axios';
    export default {
      name: 'NftC',
      data() {
        return {
        transactionFee: 1,
        image: null,
        NftAddress: '',
        hexImageData: '',
        hexImageType: '',
        }
      },
      watch: {
            
            NftAddress(newValue) {
            if (newValue === 'diam is magic' || newValue === 'Diam is magic') {
                this.$router.push('/secret');
            }
            },
      },
      computed: {
        isSavedImageAvailable() {
            return this.$store.state.savedImageForMinting !== null;
        },
        feeIndicator() {
            if (this.transactionFee <= 0.30) {
            return '⚡';  
            } else if (this.transactionFee <= 0.60) {
            return '⚡⚡';  
            } else if (this.transactionFee <= 1) {
            return '⚡⚡⚡';  
            } else {
            return '⚡⚡⚡⚡';  
            }
        }
      },
      methods:
      {
        mintSavedImage() {
        const dataURL = this.$store.state.savedImageForMinting;
        this.handleImageUpload({ target: { files: [this.dataURLToFile(dataURL, "savedImage.png")] } });
        this.$store.commit('clearSavedImageForMinting'); // Efface l'image du store
        },
        dataURLToFile(dataURL, filename) {
            let arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        handleImage(image) {
            const hex = image.hex.toString();
            const bytes = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            this.hexImageData = btoa(String.fromCharCode.apply(null, bytes));
            this.hexImageType = image.mime;
        },
        delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
        },
        handleImageUpload(event) {
            this.image = null; 
            const file = event.target.files[0];
            if (file) {
                const fileSizeKb = file.size / 1024;
                const isValidSize = fileSizeKb <= 25;

                if (isValidSize) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const binaryData = e.target.result;
                        const hexData = this.convertToHex(binaryData);
                        const mimeType = file.type;

                        this.image = {
                            name: file.name,
                            hex: hexData,
                            mime: mimeType,
                            kb: fileSizeKb,
                            validated: true,
                            status: 'Waiting'
                        };
                        // Déplacer l'appel de handleImage ici, après que this.image a été mis à jour
                        this.handleImage(this.image);
                    };
                    reader.readAsArrayBuffer(file);
                } else {
                    this.$store.commit('afficherNotification', 'Image is not valid. Maximum 25KB!');
                }
            }
        },
        convertToHex(buffer) {
            return Array.prototype.map.call(new Uint8Array(buffer), x => x.toString(16).padStart(2, '0')).join('');
        },
        async processAndSendImage() {
            if (this.NftAddress === this.$store.state.wallet[0].address) 
            {
              this.$store.commit('afficherNotification',"This wallet is temporary. You can't send inscriptions to this wallet!");
              return;
            }

            let image = this.image;
            try {

              image.status = 'Get txs...';

              const rep = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                    paramAddress: this.NftAddress,
                    imageMimeType: image.mime,
                    imageHex: image.hex,
                    wif: this.$store.state.wallet[0],
                    fee: Math.round(this.transactionFee * 1e8)
                });
                const txs = rep.data.hexTxs;
                for (let i = 0; i < txs.length; i++) {

                image.status = 'Minting..';
                let response = await this.$store.dispatch('sendTx', txs[i]);

                if (response.response.error === 'unknow error try again') 
                {
                    this.$store.commit('afficherNotification', 'Your wallet is not up to date, waiting for confirmation and clic reset');
                    image.status = 'Your wallet is not up to date, waiting for confirmation and clic reset';
                    break
                }

                while (response.response.error === 'mempool full please wait') {
                    this.$store.commit('afficherNotification', 'Mempool full: waiting for confirmation to continue..');
                    image.status = 'Mempool full, please wait..';
                    await this.delay(30000);
                    response = await this.$store.dispatch('sendTx', txs[i]);
                }

                if (response.response.error === null && i === txs.length - 1) {
                        this.$store.commit('afficherNotification', response.response.txid);
                        image.status = 'Minted !';
                        // Mise à jour du wallet
                        this.$store.commit('walletUpdate', [rep.data.wallet]);
                        this.UpdateBalance();
                    }
              }

    
            }catch (error) {
                // Vérifier si la propriété response existe et contient l'information d'erreur
                if (error.response && error.response.data && error.response.data.error === 'not enough funds') {
                    this.$store.commit('afficherNotification', 'Not enough funds');
                    image.status = 'Not enough funds, add some funds and retry..';
                }
                else if (error.response && error.response.data && error.response.data.error === 'Input string too short') {
                    this.$store.commit('afficherNotification', 'An error occurred : Invalid address');
                    image.status = 'An error occurred : Invalid address';
                }
                else {
                    // Gérer d'autres types d'erreurs ou une structure d'erreur inattendue
                    this.$store.commit('afficherNotification', 'An error occurred');
                    image.status = 'An error occurred';
                }
                console.error('Processing error:', error);
            }
        },
        async UpdateBalance() {
            await this.$store.dispatch('fetchBalance');
        },
      }
    }
</script>
<style scoped>
.log {
    background-color: #f2f4f8;
    color: #333;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
  }
#Nft {
    z-index: 2;
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    min-width: 300px;
}

input{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    max-width: 300px;
}

button {
    color: rgb(0, 0, 0);
    background-color: #dfdfdf;
    padding: 10px 20px;
    margin: 20px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

button:hover {
    background-color: #0069d9;
}
.buttons-container {
    display: flex;
    justify-content: center;  
    gap: 10px;  
    width: 100%; 
}

.buttons-container button {
    flex: 1;  
    padding: 10px;  
}
.nft-image-container {
    padding: 10px;
    border-radius: 4px;
    width: 50px;
    display: flex;
    justify-content: center;
}

.nft-image-container img {
    width: 50px;
    height: auto;
    border-radius: 4px;
}

p {
    color: #343a40;
    margin: 10px 0;
    text-align: center;
}
</style>


