<template>
    <div>



      <button @click="fetchData">Récupérer les résultats en JSON</button>
       
      <button @click="downloadData" v-if="responseData">Télécharger les données</button>
    
      <!--<p>{{ image }}</p>

      <button @click="downloadWalletsAsJson">Télécharger les Wallets</button>
      <input type="file" @change="uploadWalletsFile" accept=".json">


      <div class="wallet-grid">
        <div 
          class="wallet-square" 
          v-for="(wallet, index) in wallets" 
          :key="index">
          <p>Wallet {{ index + 1 }}</p>
          <p>{{ calculateBalance(wallet.utxos) }} DOGE</p>
          <p v-if="currentWalletIndex === index">Minting...</p>
        </div>
      </div>

        <div class="multi-mint-container">
        <div>
          <h3>MULTI MINT</h3>
          <input v-model="NftAddress" class="custom-input" placeholder="Recipient's address"/>
          <input v-model="nbMint" type="number" class="custom-input" placeholder="Number of mints"/>
          <input v-model="mintSuccess" type="number" class="custom-input" placeholder="Deja Mint ?"/>
          <input type="file" id="imageInput" class="custom-input" accept=".png, .jpg, .jpeg, .gif" @change="handleImageUpload"/>
          <button @click="processAndSendImage(nbMint, false)">Mint({{ nbMint }} nfts)</button>
          <button v-if="mintSuccess > 0" @click="downloadTransactionsJson">Download Collection json :{{ mintLeft }} nft restants</button>
          <p v-if="image !== null">Status: mint restant = {{ mintLeft }} //  {{ image.status }}</p>
        </div>
        </div>


      
            <div>
              <label for="feeSlider" style="display: block; margin-bottom: 3px;text-align: center;">Fee/kb: {{ transactionFee }} DOGE</label>
              <input type="range" id="feeSlider" v-model="transactionFee" min="0.2" max="3" step="0.1" style="width: 100%;" />
              <div style="text-align: center; font-size: 24px;"><span style="font-size: x-small;">{{ feeIndicator }}</span></div>
            </div>



      -->
    </div>
  
  </template>
  
<script>
  
  import axios from 'axios';

  export default {
    name: 'Perso2C',
    data() {
      return {
        //api
        apiEndpoint: 'https://www.shad0w.space/php/proxy.php?url=https://api.doggy.market/wallet/DAhdXNbnuixfVtKJUssCGRSFnD42jVjddo/nfts',
        offset: 0,
        limit: 40,
        totalResults: 10000,
        responseData: null,
        jsonDataUrl: '',
        //mes wallets
        wallets: [
        //1
        {
          privateKey: "QVQry2mCmeacJ9rcaxb3i8pnVo1nXvvZhbvGyQwPvUcSL2kpuzYq",
          address: "DCwJpTDV8h4X2WuVBCScSHRgLhCtQMNW7t",
          utxos: [{
            txid: "6b86fb75b287551053645f5f54e9bf51f1114ceb4921e04734c872e722689c5c",
            vout: 0,
            script: "76a914558643d077c63a21cba89aca42145f73c8fd67e788ac",
            satoshis: 25000000000
          }]
        },
        //2
        {
          privateKey: "QPEHjq2E1NDnoAzZjszRPC18RzLkCbRCnrZkWN17pzi1KtBX1iJD",
          address: "DR8t4xVVTDb1mGeajwcZ2zvUG3V3qPgx4e",
          utxos: [{
            txid: "17dce8f45c46a26b6b88819a7061fe4b7ed98e817045e3dd05e9bcb68f86aa5c",
            vout: 0,
            script: "76a914db581b0c3c257431be34871bd14440f63a1af55d88ac",
            satoshis: 25000000000
          }]
        },
        //3
        {
          privateKey: "QTH8ktXfDgMeY8Sj3Fu9naNw19GsMMhgboJ2rfXbH7FffDwXpNAi",
          address: "DRKPzwVFLjpNR1UP4VKH7ro77rcEFy8XJe",
          utxos: [{
            txid: "69bc0987e48a401cf5d6fda24488e129eff0877ea7ab1b79476dfafe34d8a0ca",
            vout: 0,
            script: "76a914dd55400cae37c4bad9f618e2cc9c4ac4d384d99d88ac",
            satoshis: 25000000000
          }]
        },
        //4
        {
          privateKey: "QR4u9uh3HHkx3SNzXLugiLWMAb4jj93GYhZSGe321E9b5hFfX1Xy",
          address: "DFjud5rNye6bdWus7R7iwHZXaEfFGdY3Uh",
          utxos: [{
            txid: "dbf8c0d015f0f2f91740e8787995e026e12991073ab1230d9b72454a401af9a9",
            vout: 0,
            script: "76a9147446b4207d3eccc85744d0007b7e65d9aa32bf2488ac",
            satoshis: 25000000000
          }]
        },
        //5
        {
          privateKey: "QTfXCssCBjNHUr91P3KJ6wAcyyLnBZD16voQgQtHF4WJZ6bfBYWD",
          address: "DMrW5Ej4U4aihekcL63qz9Tc5219YGyMhT",
          utxos: [{
            txid: "49cdbef0fa00c4524c56936a87545960d7bde9ed7ef8b03b1cd939dd7209a12c",
            vout: 0,
            script: "76a914b756b6573d8be4c0611f9f97ea089216b5bc04ef88ac",
            satoshis: 25000000000
          }]
        },
        //6
        {
          privateKey: "QTUFM6SyxdA3t2TzybAPQTZaSEeVQhQKCuvdwpa7p1mEHhq4urBX",
          address: "DUTERYNdGNLvihYw6PcaEY4Ae2jEnUmFQF",
          utxos: [{
            txid: "9411065277e8ac256d99e77f01e216d634d4104eac6fa9282309930597994511",
            vout: 0,
            script: "76a914ffb8f63427d17eb0411e06fc07826500cb34936c88ac",
            satoshis: 25000000000
          }]
        },
        //7
        {
          privateKey: "QWg1rboBkexX9QKpafxzUhndcZzJX28nhtzRtroPaiTWLcczNZHm",
          address: "DMNGcH1kgvafpe7WctUndLsjoe9MxGvrqn",
          utxos: [{
            txid: "7407e3b860c101d06b4f5195df17e6f9c9713fde6424134c74465b100fbc2648",
            vout: 0,
            script: "76a914b1ffd35140a4a4a2f40b205025550510c5b746ef88ac",
            satoshis: 25000000000
          }]
        },
        //8
        {
          privateKey: "QSbuNDy3CU4pkL842bWKu1mim2aUyBQWWqm7LJM4hCeJgcZ3Xwf6",
          address: "D63FozYmY6PqhbmDB9R7EdN3pDTDMhBJF9",
          utxos: [{
            txid: "4e3514af8c0a6e218ed35cb9871b7e185dda1acaed7f546282e53ffccba6138c",
            vout: 0,
            script: "76a91409dd5e89f7c44f1305fd0a40919e7e90319f101d88ac",
            satoshis: 25000000000
          }]
        },
        //9
        {
          privateKey: "QNqtLiLPAkqzkR8pGyCotuvSyh9qDnVya6Skbn5WW5zMo1Pu4uwM",
          address: "DP52VyeZrS1fJqN4pF3fTtXUMS96zhy1wj",
          utxos: [{
            txid: "9abcc03b3bccaa295597b8da55652e7965952258250baeb50eb96e57ea868711",
            vout: 0,
            script: "76a914c4ad3a787aa0461221de6b0d266512c633a42cb788ac",
            satoshis: 25000000000
          }]
        },
        //10
        {
          privateKey: "QRgukdFXk4eW49bgTzMprLAwF1TFpB9WWBa7fwLYmpLeAjWxNrcJ",
          address: "DGzN1b62L2vV3FYkCPAxxuk8tSExzK6xBP",
          utxos: [{
            txid: "75d0ef5f0cad3db720e98ba4921cee0f9971f0e02acd9b1fef33fa24ea11cc89",
            vout: 0,
            script: "76a91481faae32dab8fd4a7f532eb9fe9b6d77f6d12b9f88ac",
            satoshis: 25000000000
          }]
        }
      ],
      currentWalletIndex: 0,

        //variable multi mint
        nbMint: 1,
        mintSuccess: 0,
        mintLeft: 0,
        image: null,
        hexImageType: '',
        hexImageData: '',
        NftAddress: '',
        transactions: [],

        //variable mint code
        repeat: 0,
        mintData: {
          address: 'DESukXs8riTxDgx5bbCrWkpsHBZxfhAEqq',
          mime: 'text/plain;charset=utf-8',
          code: '{"p":"tap","op":"token-mint","tick":"tap","amt":"1000"}',
          wif: {
            privateKey: "QPLLk9M2wv8uwy6dinP4e25z1Y4T7Qb6mYkhNwaXC3XTfWxFG1sd",
            address: "DPnLjDrajj8oPdy8E96JW28cTYRHnGZtxz",
            utxos: [{
              txid: "3f4845d8495bac2a7251629f81db648251cd35bb1a2a52bde77faabe8644c762",
              vout: 1,
              script: "76a914cc7d7fc545bcc6339c4e33e1112502acf0c309bf88ac",
              satoshis: 121709319880
            }]
          },
          fee: 0
        },
        transactionFee: 1,
      }
    },
    computed: {
      feeIndicator() {
        if (this.transactionFee <= 0.30) {
          return '⚡';  
        } else if (this.transactionFee <= 0.60) {
          return '⚡⚡';  
        } else if (this.transactionFee <= 1) {
          return '⚡⚡⚡';  
        } else {
          return '⚡⚡⚡⚡';  
        }
      }
    },
    methods:{
      async downloadData() {
    const blob = new Blob([this.responseData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'allInscriptionIds.json';
    document.body.appendChild(link); // Nécessaire pour Firefox
    link.click();
    document.body.removeChild(link);
      },
      async fetchData() {
      let allNFTs = []; // Utilisez un nouveau tableau pour stocker les objets
      this.offset = 0; // S'assurer que l'offset est réinitialisé

      while (this.offset / this.limit < 250) { // Utilisez un calcul basé sur les pages plutôt que sur totalResults
        await this.delay(1000); // Assurez-vous d'avoir défini la méthode delay quelque part dans votre code
        const response = await axios.get(`${this.apiEndpoint}?offset=${this.offset}&limit=${this.limit}`);
        const data = response.data;

        data.data.forEach(item => {
          let nftObject = {
            inscriptionId: item.inscriptionId,
            name: "Dogi Bones #" + (allNFTs.length + 1), // Exemple de nom, ajustez selon vos besoins
            collectionSymbol: "Dogi Bones" // Exemple de collectionSymbol, ajustez selon vos besoins
          };

          // Vérifier si l'inscriptionId est unique avant d'ajouter l'objet au tableau
          if (!allNFTs.some(nft => nft.inscriptionId === item.inscriptionId)) {
            allNFTs.push(nftObject);
          }
        });

        this.offset += this.limit;
      }

      // Stocker les données formatées dans responseData
      this.responseData = JSON.stringify({ body: allNFTs }, null, 2);
    },
    async uploadWalletsFile(event) {
      const file = event.target.files[0]; // Obtention du fichier sélectionné
      if (!file) {
        return;  
      }

      try {
        const content = await file.text(); // Lire le contenu du fichier comme texte
        const wallets = JSON.parse(content); // Analyser le contenu JSON pour obtenir les données des wallets

        // Mise à jour de vos wallets ici
        // Assurez-vous que le format du JSON correspond à la structure de votre tableau de wallets
        this.wallets = wallets;

      } catch (error) {
        console.error("Erreur lors de la lecture ou de l'analyse du fichier JSON :", error);
        // Gérer l'erreur, par exemple, montrer un message à l'utilisateur
      }
    },
    downloadWalletsAsJson() {
      // Convertir les données des wallets en chaîne JSON
      const dataStr = JSON.stringify(this.wallets, null, 2);
      // Créer un blob avec les données JSON
      const blob = new Blob([dataStr], { type: "application/json" });
      // Créer un URL pour le blob
      const url = URL.createObjectURL(blob);
      // Créer un élément de lien (`a`) pour le téléchargement
      const link = document.createElement('a');
      link.href = url;
      link.download = "wallets.json";
      // Simuler un clic sur le lien pour déclencher le téléchargement
      link.click();
      // Nettoyer l'URL créée pour éviter les fuites de mémoire
      URL.revokeObjectURL(url);
    },
    calculateBalance(utxos) {
      const totalSatoshis = utxos.reduce((total, utxo) => total + utxo.satoshis, 0);
      return (totalSatoshis / 100000000).toFixed(2); 
    },
    //methode multi mint
    async processAndSendImage(x, skip) {
            if (!skip) {
                this.mintLeft = this.nbMint;
            }
            let image = this.image;
            let saveTXID = null;
            let wallet = null;
            try {
                for (let mintIndex = 0; mintIndex < x; mintIndex++) {
                    await this.delay(300);

                    //selection du wallet
                    wallet = this.wallets[this.currentWalletIndex];
                    
                    

                    const rep = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                        paramAddress: this.NftAddress,
                        imageMimeType: image.mime,
                        imageHex: image.hex,
                        wif: wallet,  
                        fee: Math.round(this.transactionFee * 1e8)
                    });

                    const txs = rep.data.hexTxs;
                    for (let i = 0; i < txs.length; i++) {
                        image.status = 'Nft: ' + mintIndex + 'minting in progress (' + wallet.address + ')';
                        let response = await this.$store.dispatch('sendTx', txs[i]);


                        //a modifier (si mempool teste wallet suivant ? non 20 tx par wallet si mem pool attendre )
                        while (response.response.error === 'mempool full please wait') {
                            this.$store.commit('afficherNotification', 'Mempool full: waiting for confirmation to continue..');
                            image.status = 'Mempool full, please wait..';
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[i]);
                        }

                        if (response.response.error === null) {
                            if (i === 1) {
                                saveTXID = response.response.txid;
                                this.mintSuccess = this.mintSuccess + 1;
                                this.repeat = 1;
                            }

                            if (i === txs.length - 1) {
                                let transactionObject = {
                                    inscriptionId: saveTXID,
                                    name: `Dogi Bones #${this.mintSuccess}`,
                                    collectionSymbol: "Dogi Bones"
                                };
                                this.transactions.push(transactionObject);

                                image.status = 'Minted !';
                                // Met à jour le wallet 
                                
                                //a modifier
                                this.wallets[this.currentWalletIndex] = rep.data.wallet;
                                this.mintLeft = this.mintLeft - 1;
                                this.currentWalletIndex = (this.currentWalletIndex + 1) % this.wallets.length;
                            }
                        }
                    } 
                } 
            } catch (error) {
                console.log(error);
            }
    },
    downloadTransactionsJson() {
            const data = {
                body: this.transactions
            };
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", "collection.json");
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
    },
    handleImage(image) {
            const hex = image.hex.toString();
            const bytes = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            this.hexImageData = btoa(String.fromCharCode.apply(null, bytes));
            this.hexImageType = image.mime;
    },
    handleImageUpload(event) {
            const file = event.target.files[0];
            if (!file) return;
            const fileSizeKb = file.size / 1024;
            const isValidSize = fileSizeKb <= 25;
            if (isValidSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryData = e.target.result;
                    const hexData = this.convertToHex(binaryData);
                    const mimeType = file.type;
                    this.image = {
                        name: file.name,
                        hex: hexData,
                        mime: mimeType,
                        kb: fileSizeKb,
                        validated: true,
                        status: 'Waiting'
                    };
                    this.handleImage(this.image);
                };
                reader.readAsArrayBuffer(file);
            } else {
                this.$store.commit('afficherNotification', 'Image is not valid. Maximum 25KB!');
            }
    },   
    //methode code mint
    convertToHex(buffer) {
            return Array.prototype.map.call(new Uint8Array(buffer), x => x.toString(16).padStart(2, '0')).join('');
    },
    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    },
    check() {
    // Assurez-vous que la chaîne est correctement convertie en ArrayBuffer ou Uint8Array
    const encoder = new TextEncoder();
    const encoded = encoder.encode(this.mintData.code);
    this.mintData.code = this.convertToHex(encoded);
    },
    async mint() {

         
                try {
                    
                    if(this.repeat > 0)
                    {
                      this.mintData.wif = this.$store.state.wallet[0];
                    }
                    
                    this.mintData.fee = Math.round(this.transactionFee * 1e8);

                    // Appel à l'API
                    const rep = await axios.post('https://www.shad0w.space/php/send.php?url=nft/mint', {
                    paramAddress: this.mintData.address,
                    imageMimeType: this.mintData.mime,
                    imageHex: this.mintData.code,
                    wif: this.mintData.wif,
                    fee: this.mintData.fee
                    });
                    const txs = rep.data.hexTxs;

                    // Envoi et gestion d'erreur
                    for (let tx = 0; tx < txs.length; tx++) {
                        let response = await this.$store.dispatch('sendTx', txs[tx]);

                        if (response.response.error === 'unknow error try again') {
                            this.$store.commit('afficherNotification', 'Your wallet is not up to date, waiting for confirmation and clic reset');
                           
                            this.resetMintState();
                            break
                        }

                        while (response.response.error === 'mempool full please wait') {
                            this.$store.commit('afficherNotification', 'Mempool full, retrying in 30secs...');
                            
                            await this.delay(30000);
                            response = await this.$store.dispatch('sendTx', txs[tx]);
                        }

                        if (response.response.error === null && tx === 1) {
                            this.$store.commit('afficherNotification', response.response.txid);
                            this.repeat = 1;
                            // Mise à jour du wallet
                            this.$store.commit('walletUpdate', [rep.data.wallet]);
                            this.UpdateBalance();
                        
                        }
                    }

                    
                } catch (error) {
                    console.log(error);
                }
            

            setTimeout(() => {
              this.$store.commit('afficherNotification', 'Mint Completed');
            }, 2000);
    },
    async UpdateBalance() {
        await this.$store.dispatch('fetchBalance');
    },
    }
  }
</script>



<style scoped>
.wallet-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espacement entre les carrés */
  width: 700px;

}

.wallet-square {
  width: 100px; /* Largeur du carré */
  height: 70px; /* Hauteur du carré */
  border: 1px solid #ddd; /* Bordure du carré */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9; /* Couleur de fond du carré */
  border-radius: 5px; /* Arrondissement des angles du carré */
  padding: 10px; /* Padding à l'intérieur du carré */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Ombre portée du carré */

}

.wallet-square p {
  margin: 4px 0; /* Marge entre les paragraphes dans le carré */
  text-align: center; /* Centrage du texte */
}


.multi-mint-container {
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #f0f0f0; /* Couleur de fond légère pour distinguer le bloc */
}

.multi-mint-container h3 {
  margin-bottom: 15px; /* Espacement sous le titre */
  text-align: center; /* Centrer le titre */
}

.custom-input {
  display: block; /* Les inputs prennent toute la largeur */
  margin: 10px 0; /* Espacement vertical entre les inputs */
  padding: 8px; /* Padding pour augmenter la zone cliquable */
  border: 1px solid #ccc; /* Couleur de bordure plus douce */
  border-radius: 4px; /* Bordures légèrement arrondies pour les inputs */
  width: calc(100% - 18px); /* Ajuster la largeur pour inclure le padding et la bordure */
}

button {
  display: block; /* Les boutons prennent toute la largeur */
  margin: 10px 0; /* Espacement vertical */
  padding: 10px; /* Padding confortable pour le texte du bouton */
  background-color: #4CAF50; /* Couleur de fond pour les boutons */
  color: white; /* Texte en blanc pour le contraste */
  border: none; /* Pas de bordure pour les boutons */
  border-radius: 4px; /* Bordures arrondies pour les boutons */
  cursor: pointer; /* Curseur pointeur pour indiquer l'interactivité */
}

button:hover {
  background-color: #45a049; /* Un peu plus foncé au survol pour l'effet interactif */
}
</style>
