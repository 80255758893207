<template>
    <div class="popup-overlay" v-if="isVisible">
      <div class="popup">
        <h2><span class="red">WARNING</span></h2>
        <p>
            <span class="red">The wallet in this application is not intended for holding assets.</span> 
            <br>
            It is meant for transfers only and should be considered temporary. 
            <br>
            <span class="red">Do not import your own wallet into this application. Doing so may result in the loss of your assets.</span></p>
        <button @click="closePopup">Understand</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WalletWarningPopup',
    props: {
      isVisible: Boolean
    },
    methods: {
      closePopup() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style>
  .red{
    color: red;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup {
    max-width: 500px;
    min-height: 250px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }
  
  button {
    cursor: pointer;
    background-color: #3498db;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    margin-top: 15px;
  }
  </style>
  