import { createRouter, createWebHistory } from 'vue-router';
import Wallet from '../components/Wallet.vue';
import Mint from '../components/Mint.vue';
import Nft from '../components/Nft.vue';
import Deploy from '../components/Deploy.vue';
import Withdraw from '../components/Withdraw.vue';
import store from '../store/index.js';  
import DrawingC from '../components/Secret.vue';
import Games from '../components/Games.vue';
import multinft from '../components/MultiNft.vue';
import perso from '../components/Perso.vue';
import perso2 from '../components/Perso2.vue';


const routes = [
  { path: '/', redirect: '/wallet' },
  { path: '/wallet', component: Wallet },
  { path: '/mint', component: Mint },
  { path: '/deploy', component: Deploy },
  { path: '/nft', component: Nft },
  { path: '/secret', component: DrawingC },
  { path: '/games', component: Games },
  { path: '/multinft', component: multinft },
  { path: '/withdraw', component: Withdraw },
  { path: '/perso', component: perso },
  { path: '/perso2', component: perso2 },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

 
router.beforeEach((to, from, next) => {
  if (store.state.wallet.length === 0 && to.path !== '/wallet') {
    next({ path: '/wallet' });
  } else {
    next();
  }
});

export default router;
